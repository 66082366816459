import Head from "next/head";
import Footer from "../Footer";
import Nav from "../Nav";
import CookieBanner from "../CookieBanner";
import {useRouter} from "next/router";
import localized from "../../locale/locales";
import MobileFloatingCta from "../MobileFloatingCta";
import MobileThemeDeco from "../MobileThemeDeco";
import {useState} from "react";
import TopBanner from "../TopBanner";

const isProduction = process.env.NODE_ENV === 'production';

export default function MainLayout({
                                       children,
                                       hasCookiePreferences,
                                       cookiePreferences = {
                                           ad_storage: 'denied',
                                           analytics_storage: 'denied'
                                       },
                                       className = '',
                                       hiddenNav,
                                       footerTheme = 'dark',
                                       title,
                                       description,
                                       image
                                   }) {
    const {locale, asPath} = useRouter();
    const {og, common} = localized[locale];

    const [activeFloatingCta, setActiveFloatingCta] = useState(false);

    title = title ? `${title} | ${og.title}` : og.title;

    if (!description) description = og.description;
    if (!image) image = `https://nextmove.cz/img/og/${locale}_og-image.png`;

    return (
        <div className="flex flex-col justify-center min-h-screen antialiased pt-nav">
            <Head>
                <title>{title}</title>
                <meta property="og:title" content={title}/>
                <meta property="og:image" content={image}/>
                <meta property="og:site_name" content={og.name}/>
                <meta property="og:description" content={description}/>
                <meta name="description" content={description}/>

                {isProduction && (
                    <>
                        <script dangerouslySetInnerHTML={{
                            __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('consent', 'default', {
                    ad_storage: '${cookiePreferences.ad_storage}',
                    analytics_storage: '${cookiePreferences.analytics_storage}',
                    wait_for_update: 2000
                });`
                        }}/>

                        <script dangerouslySetInnerHTML={{
                            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-T6WZ2FX');`
                        }}/>
                    </>
                )}
            </Head>

            {isProduction && (
                <noscript dangerouslySetInnerHTML={{
                    __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T6WZ2FX"
                        height="0" width="0" style="display:none;visibility:hidden"></iframe>`
                }}/>
            )}

            <Nav hiddenNav={hiddenNav}/>

            <TopBanner hidden={hiddenNav}>
                {common.topBanner}
            </TopBanner>

            <MobileFloatingCta setActiveFloatingCta={setActiveFloatingCta}/>

            <MobileThemeDeco/>

            <main className={`flex-1 pt-top-banner ${className}`}>
                {children}
            </main>

            <Footer theme={footerTheme}
                    className={`${activeFloatingCta ? 'pb-[78px] lg:pb-0' : 'pb-0'} transition-all duration-500`}/>

            {(asPath !== '/cookie-settings' && !hasCookiePreferences) && (
                <CookieBanner/>
            )}
        </div>
    )
}
export const inputStyles = (error = false, size = 'big') => {
    const classes = [
        'w-full flex-1 bg-gray-100 border-2 px-8 rounded-full transition-colors duration-300'
    ];

    classes.push(size === 'big' ? 'py-4' : 'py-3');

    if (error) {
        classes.push('border-red-400 focus:border-red-400 focus:bg-white focus:outline-none');
    } else {
        classes.push('border-gray-100 focus:border-black focus:bg-white focus:outline-none hover:border-black hover:bg-white');
    }

    return classes.join(' ');
}

export const selectStyles = (error = false, size = 'big') => {
    const classes = [
        'w-full flex-1 border px-8 rounded-full transition-colors duration-300 cursor-pointer'
    ];

    classes.push(size === 'big' ? 'py-4' : 'py-3');

    if (error) {
        classes.push('border-red-400 focus:border-red-400 focus:outline-none');
    } else {
        classes.push('border-[#eee] focus:border-black focus:outline-none mouse-hover:border-black/50');
    }

    return classes.join(' ');
}

export const buttonStyles = (theme = 'primary', size = 'big', ring = true) => {
    let themeClasses;
    let sizeClasses;

    switch (theme) {
        case 'ghost':
            themeClasses = 'bg-transparent text-black ring-0 underline px-0 mouse-hover:no-underline';

            if (size === 'small') {
                sizeClasses = 'text-2xs';
            } else if (size === 'medium') {
                sizeClasses = 'py-4 text-xs';
            } else {
                sizeClasses = 'py-6 text-xs';
            }
            break;
        case 'secondary':
            themeClasses = 'bg-white text-black mouse-hover:bg-gray-600 mouse-hover:text-white focus:bg-gray-700 focus:text-white';
            if (ring) themeClasses += ' ring-8 ring-gray-700 mouse-hover:ring-0 focus:ring-0';

            if (size === 'small') {
                sizeClasses = 'py-3 px-7 text-2xs';
            } else if (size === 'medium') {
                sizeClasses = 'py-4 px-10 text-xs';
            } else {
                sizeClasses = 'py-6 px-14 text-xs';
            }
            break;
        case 'primary':
        default:
            themeClasses = 'bg-black text-white mouse-hover:bg-gray-200 mouse-hover:text-black focus:bg-gray-100 focus:text-black';
            if (ring) themeClasses += ' ring-8 ring-gray-200 mouse-hover:ring-0 focus:ring-0';

            if (size === 'small') {
                sizeClasses = 'py-3 px-7 text-2xs';
            } else if (size === 'medium') {
                sizeClasses = 'py-4 px-10 text-xs';
            } else {
                sizeClasses = 'py-6 px-14 text-xs';
            }
            break;
    }

    return `relative uppercase inline-flex rounded-full font-semibold leading-none transition-all duration-300 justify-center ${themeClasses} ${sizeClasses}`;
};

// Forcing tailwind to keep these classes (not purge them)
const forceClasses = 'grid-cols-3 grid-cols-1';
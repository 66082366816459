export default function VoucherBg2() {
    return (
        <div className="absolute top-0 left-0 z-10 w-full h-full">
            <svg className="absolute top-0 left-0"
                 width="92" height="105" viewBox="0 0 92 105" fill="none" xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M87.2577 0C80.2496 15.8773 61.4239 34.7401 39.9874 56.1812C26.5208 69.7515 12.6421 83.729 0 98.6563V104.763C13.1917 88.8857 28.582 73.5512 43.0105 59.1667C65.2715 36.6399 84.9216 16.9629 91.7923 0"
                    fill="currentColor"/>
            </svg>

            <svg className="absolute top-0 right-0"
                 width="71" height="87" viewBox="0 0 71 87" fill="none" xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M70.2101 86.8036C33.098 54.406 10.776 26.6562 0.368164 0H4.7504C14.8843 24.6057 36.3847 51.3987 70.2101 81.3357V86.8036Z"
                    fill="currentColor"/>
            </svg>

        </div>
    )
}
import {navLinks} from "../helpers/content";
import {useRouter} from "next/router";
import localized from "../locale/locales";
import BurgerLink from "./BurgerLink";
import BurgerSubmenu from "./BurgerSubmenu";

export default function BurgerLinks({className = '', onClick}) {
    const {locale} = useRouter();
    const lang = localized[locale];

    return (
        <ul className="flex flex-col justify-center items-center gap-0 list-none w-full">
            {navLinks.map(({href, label, type}, key) => {
                if (type === 'button') return null;

                if (type === 'submenu') {
                    return (
                        <BurgerSubmenu label={label(lang)}
                                       items={navLinks[key].items}
                                       className={className}
                                       key={`BurgerSubmenu: ${key}`}
                                       onClick={() => onClick(href)}

                        />
                    )
                }

                return (
                    <BurgerLink href={href}
                                label={label(lang)}
                                onClick={() => onClick(href)}
                                className={className}
                                key={`BurgerLink: ${key}`}
                    />
                )
            })}
        </ul>
    )
}

import Link from 'next/link';
import {config} from '@react-spring/web';
import useScrollTo from 'react-spring-scroll-to-hook';
import {useRouter} from "next/router";

export default function NavLink({href, children, className = '', ...rest}) {
    const {asPath} = useRouter();
    const {scrollTo} = useScrollTo(config.default);

    const activeClass = (href && asPath === href) ? 'font-bold' : 'font-medium';
    const classes = `inline-flex py-7 px-5 text-sm transition-all duration-300 mouse-hover:bg-black mouse-hover:text-white ${activeClass} ${className}`;

    if (asPath.split('#')[0] === '/' && href.includes('/#')) {
        return (
            <button {...rest} className={classes} onClick={() => scrollTo(href.substring(1), -100)}>
                {children}
            </button>
        );
    }

    if (href) {
        return (
            <Link href={href}
                  className={classes}
                  {...rest}
            >
                {children}
            </Link>
        )
    }

    return (
        <button {...rest} className={classes} onClick={() => scrollTo(href, -100)}>
            {children}
        </button>
    )
}

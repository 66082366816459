export const formatNumber = (value) => {
    // value = numberOrZero(value);
    const formatted = new Intl.NumberFormat(
        'cs-CZ',
        {
            style: 'currency',
            currency: 'CZK',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value);

    return formatted.replace('Kč', '').trim() + ',-';
};

export const mapNumber = (value, in_min, in_max, out_min, out_max, limit = true) => {
    const mapped = (value - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;

    if (limit) {
        return minMax(mapped, out_min, out_max);
    }

    return mapped;
}

export const minMax = (value, min = 0, max = 100) => {
    return Math.min(Math.max(value, min), max);
}

export const numberToPercentage = (value, total) => {
    return (value / total) * 100;
}

export const percentageToNumber = (value, total) => {
    return (value / 100) * total;
}

export const randomTrueFalse = () => {
    return Math.random() >= 0.5;
}
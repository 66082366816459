import Container from "./Container";
import {useEffect, useRef} from "react";

export default function TopBanner({hidden, children}) {
    const bannerRef = useRef();

    useEffect(() => {
        if (bannerRef.current) {
            const bannerHeight = bannerRef.current.offsetHeight;
            document.documentElement.style.setProperty('--top-banner-height', `${bannerHeight}px`);
        }
    }, []);

    return (
        <div
            className={`tag-primary absolute top-nav left-0 w-full pt-2 z-20 text-white text-center font-bold transition-all duration-500 ${hidden ? 'opacity-0 -translate-y-full' : ''}`}
            ref={bannerRef}
        >
            <Container className="py-2.5">
                {children}
            </Container>
        </div>
    )
}

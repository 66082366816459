export default function VoucherBg3() {
    return (
        <div className="absolute top-0 left-0 z-10 w-full h-full">
            <svg className="absolute top-0 right-0"
                 width="139" height="54" viewBox="0 0 139 54" fill="none" xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M138.588 54C114.689 51.5881 92.4377 45.8263 69.6374 36.9826C44.0899 27.201 20.7401 14.7395 0 0.53598L6.31817 0C26.7836 14.0695 46.0128 23.5831 71.1482 33.3648C93.5365 42.0744 115.375 47.7022 139 50.1141L138.588 54Z"
                    fill="currentColor"/>
            </svg>

            <svg className="absolute bottom-0 left-0"
                 width="94" height="46" viewBox="0 0 94 46" fill="none" xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M94 46C60.783 31.8462 24.8094 14.2899 0.13783 0L0 4.49113C22.7419 17.5562 52.5132 32.3905 83.3871 45.8639L94 46Z"
                    fill="currentColor"/>
            </svg>
        </div>
    )
}
import {isMobile} from 'react-device-detect';
import {useEffect, useRef, useState} from "react";
import BurgerMenu from "./BurgerMenu";
import Container from "./Container";
import NavLink from "./NavLink";
import {navLinks} from "../helpers/content";
import {useRouter} from "next/router";
import localized from "../locale/locales";
import LangSwitcher from "./LangSwitcher";
import NavButton from "./NavButton";
import NavSubmenu from "./NavSubmenu";
import LogoLink from "./LogoLink";

let prevScrollPos = 0;

export default function Nav({hiddenNav}) {
    const {locale} = useRouter();
    const lang = localized[locale];

    const navRef = useRef();
    const [visible, setVisible] = useState(true);
    const [activeBurger, setActiveBurger] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;

            if (hiddenNav) {
                setVisible(false);
            } else if (isMobile) {
                setVisible(true);
            } else {
                setVisible(currentScrollPos < 100 || prevScrollPos > currentScrollPos || activeBurger);
            }

            prevScrollPos = currentScrollPos;
        };

        window?.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => window?.removeEventListener('scroll', handleScroll);
    }, [activeBurger, hiddenNav]);

    useEffect(() => {
        setVisible(!hiddenNav);
    }, [hiddenNav]);

    useEffect(() => {
        const handleNavHeight = () => {
            const navHeight = navRef.current.offsetHeight - 10; // 10 is border radius offset
            document.documentElement.style.setProperty('--nav-height', `${navHeight}px`);
        }

        if (navRef.current) {

            handleNavHeight();
            window?.addEventListener('resize', handleNavHeight);
        }

        return () => window?.removeEventListener('resize', handleNavHeight);
    }, []);

    return (
        <nav
            className={`fixed top-0 left-0 w-full bg-white rounded-bl-lg rounded-br-lg z-50 shadow-xl transform transition-transform duration-300 ${!visible ? '-translate-y-full' : ''}`}
            ref={navRef}
        >
            <Container className="flex justify-between items-center">
                <LogoLink/>

                <div className="hidden lg:flex gap-8">
                    <div className="flex items-center gap-2">
                        {navLinks.map(({href, label, type}, key) => {
                            if (type === 'button') {
                                return (
                                    <NavButton href={href}
                                               label={label(lang)}
                                               key={`NavLink: ${key}`}
                                               className="ml-3 mr-7"
                                    />
                                );
                            } else if (type === 'submenu') {
                                return (
                                    <NavSubmenu label={label(lang)}
                                                items={navLinks[key].items}
                                                key={`NavLink: ${key}`}
                                    />
                                );
                            }

                            return (
                                <NavLink href={href}
                                         key={`NavLink: ${key}`}
                                >
                                    {label(lang)}
                                </NavLink>
                            )
                        })}

                        <LangSwitcher/>
                    </div>
                </div>

                <BurgerMenu activeBurger={activeBurger} setActiveBurger={setActiveBurger}/>
            </Container>
        </nav>
    )
}

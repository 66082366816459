export default function BurgerIcon({active}) {
    return (
        <div className={`burger ${active ? 'active' : ''}`}>
            <div className="line line-1"/>
            <div className="line line-2">
                <div className="dot"/>
            </div>
            <div className="line line-3"/>
        </div>
    )
}
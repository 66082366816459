import {randomTrueFalse} from "../helpers/numbers";

function SvgComponent({variant, ...rest}) {
    switch (variant) {
        case 0:
            return (
                <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
                    <g>
                        <path d="M6.14062 6.15869L30.1278 30.2434" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M9.21094 3.71863V9.15483" stroke="currentColor" strokeWidth="1" strokeMiterlimit="10"/>
                        <path d="M11.9696 6.51318L11.8516 11.5476" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M13.9219 5.63904L17.8774 9.07212" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M22.0268 5.67444L17.9531 9.27297" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M22.0613 1.64429L18.0703 5.59735" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M13.9609 1.67969L18.07 5.5973" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M23.8672 6.51318L24.3454 11.7071" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M26.817 3.51746L26.7344 9.43228" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M32.6028 9.31458L26.7344 9.43275" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M29.7362 12.1508L24.3047 11.9912" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M30.3721 14.2245L26.8594 18.1835" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M34.4018 13.9885L30.3281 18.1425" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M34.3642 22.2137L30.6094 18.1838" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M30.5315 22.2964L26.8594 18.1838" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M30.0913 24.1454L24.1875 24.4526" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M32.7799 26.87L26.7344 26.8109" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M26.6562 32.7257L26.733 26.8109" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M24 30.0958L24.183 24.4528" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M22.2947 30.8581L17.9141 27.0114" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M22.2662 34.6812L18.0391 30.4858" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M13.7266 34.6401L18.2371 30.5275" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M13.7266 30.7278L18.1603 27.1293" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M12.0132 30.2083L11.8125 24.4944" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M9.29133 32.885L9.25 26.9288" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M3.1875 26.7277L9.25661 26.9286" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M6.0625 24.0156L12.1729 24.4115" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M5.42188 22.2964L8.85789 18.2192" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M5.34375 13.9053L9.17532 18.142" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M1.42969 13.7457L5.66271 18.142" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M1.3125 22.3789L5.6636 18.1422" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M6.21875 12.186L11.727 11.8669" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M3.26562 9.31431L9.45281 9.27295" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M18 1L18.1181 35.0413" stroke="currentColor" strokeWidth="1" strokeMiterlimit="10"/>
                        <path d="M1 18.189L35 18.1417" stroke="currentColor" strokeWidth="1" strokeMiterlimit="10"/>
                        <path d="M30.0945 6.06384L5.85938 30.2845" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                    </g>
                </svg>
            );
        case 1:
        default:
            return (
                <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
                    <g>
                        <path
                            d="M25.9112 21.6764L18.3403 19.6935L16.228 27.388L14.1305 19.6885L6.55469 21.6714L12.0231 15.9599L6.55962 10.2483L14.1305 12.2312L16.2379 4.54187L18.3403 12.2363L25.9161 10.2534L20.4477 15.9649L25.9112 21.6764Z"
                            stroke="currentColor" strokeWidth="1" strokeMiterlimit="10"/>
                        <path d="M8.36719 2.49316L23.8198 29.3101" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M23.7161 2.59412L8.55469 29.3555" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M1 16.151L31.2735 15.7524" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M6.48009 16.0753L1.92969 11.166" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M2.17188 21.0355L6.48045 16.0758" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M4.79688 25.8683L11.3313 24.3749" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M13.3348 30.9996L11.3359 24.3749" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M19.0859 30.8019L20.9861 24.4193" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M27.272 25.6656L20.9844 24.4193" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M30.2029 20.4853L25.9141 15.8989" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M30.0548 10.8195L25.9141 15.6531" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M27.4875 5.93506L21.3281 7.42853" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M19.1406 1L20.8433 7.27662" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M13.1423 1L11.3359 7.42798" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                        <path d="M4.70312 5.83301L11.3313 7.42739" stroke="currentColor" strokeWidth="1"
                              strokeMiterlimit="10"/>
                    </g>
                </svg>
            )
    }
}

export default function SvgSnowflake({variant = 0, style = {}, ...rest}) {
    return (
        <div {...rest}>
            <SvgComponent variant={variant}
                          className={randomTrueFalse() ? 'animate-snowflake' : 'animate-snowflake-reverse'}
                          style={style}
            />
        </div>
    )
}
import {randomString} from "../helpers/strings";
import {useMemo} from "react";

export default function SvgLogoSmall({id, ...rest}) {
    if (!id) {
        id = useMemo(() => randomString('logo-small'), []);
    }
    return (
        <svg viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg"
             {...rest}
        >
            <path d="M20.2101 26V2.4064H15.8135V26H20.2101Z" fill={`url(#${id})`}/>
            <path d="M4.39666 26V5.46416L5.53653 7.81628L15.4697 26H19.9388L5.73556 0H0V26H4.39666Z"
                  fill="currentColor"/>
            <path
                d="M20.2102 2.24356C20.2102 1.01322 19.2331 0 18.0028 0C16.7905 0 15.7954 0.995129 15.7954 2.24356C15.7954 3.4739 16.7724 4.48713 18.0028 4.48713C19.2331 4.48713 20.2102 3.4739 20.2102 2.24356Z"
                fill="currentColor"/>
            <defs>
                <linearGradient id={id} x1="18.0138" y1="2.40368" x2="18.0138"
                                y2="26.0045" gradientUnits="userSpaceOnUse">
                    <stop stopOpacity="0.6" stopColor="currentColor"/>
                    <stop offset="1" stopOpacity="0" stopColor="currentColor"/>
                </linearGradient>
            </defs>
        </svg>
    )
}
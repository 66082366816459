import SvgBat from "../svg/SvgBat";
import styles from "../styles/components/MobileHalloweenDeco.module.scss";
import {randomString} from "../helpers/strings";
import {useMemo} from "react";

export default function MobileHalloweenDeco() {
    const id = useMemo(() => randomString('mobile-halloween-deco'), []);

    const bats = useMemo(() => {
        return Array.from({length: 16}, (_, i) => {
            const variant = i % 3;
            let aspectClass;

            if (variant === 0) {
                aspectClass = 'aspect-[11/5]';
            } else if (variant === 1) {
                aspectClass = 'aspect-[15/7]';
            } else {
                aspectClass = 'aspect-[25/8]';
            }

            return (
                <SvgBat
                    variant={variant}
                    className={`absolute opacity-0 fill-black ${styles.deco} ${aspectClass}`}
                    key={`Decoration: ${id}-${i}`}
                />
            );
        });
    }, [id]);

    return (
        <div className="fixed bottom-0 left-0 w-full h-24 z-[25] pointer-events-none">
            {bats}
        </div>
    )
}

import {useEffect} from "react";
import {useRouter} from "next/router";
import useScrollTo from 'react-spring-scroll-to-hook';
import {config} from '@react-spring/web';
import localized from "../locale/locales";
import BurgerIcon from "./BurgerIcon";
import BurgerLinks from "./BurgerLinks";
import BurgerSocial from "./BurgerSocial";
import BurgerLangSwitcher from "./BurgerLangSwitcher";

export default function BurgerMenu({activeBurger, setActiveBurger}) {
    const {locale} = useRouter();
    const lang = localized[locale];

    const router = useRouter();
    const {scrollTo} = useScrollTo(config.default);

    const handleClick = (href) => {
        if (!href) {
            setActiveBurger(false);
        } else {
            if (router.asPath === '/' && href.includes('/#')) {
                scrollTo(href.substring(1), -100);
                setActiveBurger(false);
            } else if (!href.includes('#') || href.includes('/#')) {
                router.push(href).then(() => setActiveBurger(false));
            } else {
                scrollTo(href, -100);
                setActiveBurger(false);
            }
        }
    }

    useEffect(() => {
        activeBurger ? document.body.classList.add('overflow-hidden') : document.body.classList.remove('overflow-hidden');
    }, [activeBurger]);

    return (
        <div className="lg:hidden">
            <button className="py-7 text-0 inline-flex gap-2" onClick={() => setActiveBurger(!activeBurger)}>
                <BurgerIcon active={activeBurger}/>
                {lang.common.menu}
            </button>
            <div
                className={`absolute top-0 left-0 w-screen h-screen pt-16 pb-8 bg-white overflow-auto flex flex-col items-center -z-1 transform transition-all duration-500 ${activeBurger ? 'opacity-100 pointer-events-auto ' : 'opacity-0 pointer-events-none delay-11500'}`}>
                <div className="py-8 flex flex-col justify-center items-center gap-12 w-full">
                    <BurgerLinks
                        onClick={handleClick}
                        className={!activeBurger ? 'opacity-0 -translate-y-8' : ''}
                    />

                    <BurgerLangSwitcher
                        onClick={handleClick}
                        className={!activeBurger ? 'opacity-0 -translate-y-8' : ''}
                    />

                    <BurgerSocial
                        className={!activeBurger ? 'opacity-0 -translate-y-8' : ''}
                    />
                </div>
            </div>
        </div>
    )
}

export default function BurgerLink({href, label, className = '', ...rest}) {
    return (
        <button
            className={`text-xl transition-all duration-300 w-full py-5 mouse-hover:bg-black mouse-hover:text-white ${className}`}
            {...rest}
        >
            {label}
        </button>
    )
}

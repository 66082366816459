import {buttonStyles} from "../helpers/styles";
import ButtonOrLink from "./ButtonOrLink";
import ButtonHalloween from "./ButtonHalloween";
import {isChristmas, isHalloween} from "../helpers/themes";
import ButtonChristmas from "./ButtonChristmas";

export default function Button({label, href, className = '', theme = 'primary', size = 'big', ...rest}) {
    if (isHalloween) {
        return <ButtonHalloween label={label} href={href} className={className} theme={theme} size={size} {...rest}/>
    }

    if (isChristmas) {
        return <ButtonChristmas label={label} href={href} className={className} theme={theme} size={size} {...rest}/>
    }

    const buttonClasses = buttonStyles(theme, size, true);
    const classes = `${buttonClasses} ${className}`;

    return (
        <ButtonOrLink className={classes}
                      href={href}
                      {...rest}
        >
            {label}
        </ButtonOrLink>
    )
}

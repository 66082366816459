import SvgFlagCS from "../svg/SvgFlagCS";
import SvgFlagEN from "../svg/SvgFlagEN";

export default function Flag({locale, ...rest}) {
    let svg;

    switch (locale) {
        case 'en':
            svg = <SvgFlagEN {...rest}/>;
            break;
        case 'cs':
        default:
            svg = <SvgFlagCS {...rest}/>;
            break;
    }

    return svg;

    // return (
    //     <div {...rest}>
    //         {svg}
    //     </div>
    // )
}

import {socialLinks} from "../helpers/content";

export default function BurgerSocial({className = ''}) {
    return (
        <div
            className={`flex items-center gap-6 transition-all duration-300 text-gray-600 ${className}`}>
            <a href={socialLinks.instagram} target="_blank" rel="nofollow noreferrer"
               className="inline-flex items-center justify-center transition-colors duration-200 mouse-hover:text-black">
                Instagram
            </a>
            <span>|</span>
            <a href={socialLinks.facebook} target="_blank" rel="nofollow noreferrer"
               className="inline-flex items-center justify-center transition-colors duration-200 mouse-hover:text-black">
                Facebook
            </a>
        </div>
    )
}

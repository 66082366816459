import {useState} from "react";
import SvgChevronDown from "../svg/SvgChevronDown";
import Link from "next/link";
import {useRouter} from "next/router";
import localized from "../locale/locales";

export default function BurgerSubmenu({label, items, className = '', onClick, ...rest}) {
    const {locale} = useRouter();
    const lang = localized[locale];

    const [active, setActive] = useState(false);

    return (
        <div
            className={`text-xl w-full overflow-hidden transition-all duration-300 ${className}`}
            {...rest}
        >
            <div
                className="py-5 flex justify-center items-center gap-2 cursor-pointer mouse-hover:bg-black mouse-hover:text-white transition-all duration-300"
                onClick={() => setActive(!active)}
            >
                {label} <SvgChevronDown width={10}
                                        className={`transition-transform duration-200 will-change-transform ${active ? 'rotate-180' : ''}`}/>
            </div>
            <div className={`w-[120%] relative accordion accordion-solid accordion-0 ${active ? 'accordion-open' : ''}`}
                 style={{left: '-10%'}}
            >
                <div className="flex flex-col shadow-submenu">
                    {items.map(({href, label}, key) => {
                        return (
                            <Link href={href}
                                  key={`BurgerSubmenu: ${key}`}
                                  className="block text-center py-5 mouse-hover:bg-black mouse-hover:text-white"
                                  onClick={() => {
                                      setActive(false);
                                      onClick(href);
                                  }}
                            >
                                {label(lang)}
                            </Link>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

import Link from 'next/link';

export default function NavSubmenuLink({label, href, className = ''}) {
    return (
        <Link href={href}
              className={`flex items-center justify-center py-4 text-black text-sm mouse-hover:font-semibold ${className}`}
        >
            {label}
        </Link>
    )
}

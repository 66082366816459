import {useRouter} from "next/router";
import Link from "next/link";
import Flag from "./Flag";
import {setLocaleCookies} from "../helpers/cookies";

export default function BurgerLangSwitcher({className = '', onClick}) {
    const {locale, locales, asPath} = useRouter();

    const path = asPath.split('?')[0].split('#')[0];

    return (
        <div className="flex items-center gap-6">
            {locales.map((lang, key) => {
                return (
                    <Link href={path}
                          locale={lang}
                          key={`Burger Menu Lang: ${key}`}
                          className={`block rounded-md overflow-hidden border-2 transition-all duration-300 ${locale === lang ? 'border-black' : 'border-gray-300 grayscale opacity-60 mouse-hover:grayscale-0'} ${className}`}
                          onClick={() => {
                              setLocaleCookies(lang)
                              onClick(asPath)
                          }}
                    >
                        <Flag locale={lang} width={40}/>
                    </Link>
                )
            })}
        </div>
    )
}

import {useMemo, useState} from "react";
import {buttonStyles} from "../helpers/styles";
import ButtonOrLink from "./ButtonOrLink";
import style from '../styles/components/ButtonChristmas.module.scss';
import {randomString} from "../helpers/strings";
import SvgSnowflake from "../svg/SvgSnowflake";

function Decorations() {
    const id = useMemo(() => randomString('button-christmas-decorations'), []);
    const deco = useMemo(() => {
        return Array.from({length: 8}, (_, i) => {
                return (
                    <SvgSnowflake variant={i % 2}
                                  className={`absolute opacity-0 aspect-square ${style.deco}`}
                                  key={`Decoration: ${id}-${i}`}
                                  style={{
                                      animationDelay: `${i * 0.2}s`,
                                  }}
                    />
                )
            }
        )
    }, [id]);

    return (
        <div className="overlay pointer-events-none">
            {deco}
        </div>
    )
}

export default function ButtonChristmas({label, href, className = '', theme = 'primary', size = 'big', ...rest}) {
    const [isHovered, setIsHovered] = useState(false);
    const buttonClasses = buttonStyles(theme, size, false);

    return (
        <div className={`relative ${style.button} inline-flex ${className}`}>
            <ButtonOrLink className={`${buttonClasses} relative z-10 w-full`}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseOut={() => setIsHovered(false)}
                          href={href}
                          {...rest}
            >
                {label}
            </ButtonOrLink>

            {isHovered && <Decorations/>}
        </div>
    )
}

export default function SvgLogoHalloween(props) {
    return (
        <svg viewBox="0 0 53 48" fill="none" xmlns="http://www.w3.org/2000/svg"
             {...props}
        >
            <g clipPath="url(#clip0_5731_1365)">
                <path d="M41.2 10.4C39.4 10.3 37.6 10.6 35.9 11.3C34.2 10.2 33.2 9.3 32.6 8.6C34 8.4 38.7 8.1 41.2 10.4ZM28.2 0.699996C30.3 -0.200004 32.3 -0.200004 34.6 0.799996C35 1.1 35.3 1.4 35.3 1.6C35.3 1.7 35.1 1.9 34.5 2.1C32.9 2.8 29.9 4.1 29.8 6.7C29.7 7.9 30.3 9.2 31.6 10.5C30.3 10 28.8 9.6 27.2 9.5C24.5 9.4 21.9 10.1 19.4 11.7C20.3 9 23.4 2.7 28.2 0.699996ZM15.7 7.6C17 7.2 18.2 7.5 18.8 7.7C18 9.2 17.4 10.6 17.1 11.3C15.5 10.7 13.8 10.3 12.2 10.3C13.3 8.9 14.4 8 15.7 7.6ZM51.8 35.2C50 40.5 45.7 44.7 40.5 46.2C39.3 46.5 38.2 46.6 37.3 46.5H37.1C36.7 46.4 36 46.3 35.2 45.9H35.1C41.7 40.5 40 30 40 30C38.4 39.5 34.7 43.5 32.7 45.1C32.3 45.2 31.9 45.3 31.5 45.6C29 47.6 25.7 48.2 22.5 47.1C21.4 46.7 20.4 46.2 19.4 45.6C19.3 45.6 19.3 45.5 19.2 45.5C18.9 45.3 18.3 44.8 17.6 45.1C17.2 45.2 16.9 45.5 16.6 45.9C16.5 46 16.4 46.2 16.3 46.2C16.1 46.3 15.8 46.5 15.4 46.5C14.5 46.7 13.4 46.6 12.2 46.2C7.00001 44.7 2.70001 40.5 0.900007 35.2C-1.19999 28.8 0.400007 20.3 4.80001 15.6C7.70001 12.4 12.5 11.6 16.8 13.4C16.6 13.6 16.4 13.7 16.2 13.9C16.1 14 16 14.1 15.9 14.1C15.9 14.1 15.9 14.2 15.8 14.2C10.5 19.8 12 29.1 12 29.1C13.7 18.9 17.8 15 19.7 13.6C20 13.4 20.2 13.3 20.5 13.1C20.7 13 20.8 13 20.8 13H20.7C22.7 11.8 24.8 11.3 26.9 11.4C31.9 11.6 35.5 15.5 35.5 15.5C35.9 15.9 36.5 15.9 36.9 15.6C37.3 15.2 37.4 14.6 37 14.2C37 14.2 36.6 13.8 36.1 13.3C40.3 11.5 45 12.4 47.9 15.5C52.3 20.3 54 28.7 51.8 35.2Z" fill="#000"/>
                <path opacity="0.6" d="M33.0016 37.3V22.2H30.1016V37.3H33.0016Z" fill="url(#paint0_linear_5731_1365)"/>
                <path d="M22.8 37.3V24.1L23.6 25.7L29.9 37.3H32.8L23.7 20.7H20V37.3H22.8Z" fill="currentColor"/>
                <path d="M33.0031 22.1C33.0031 21.3 32.4031 20.7 31.6031 20.7C30.8031 20.7 30.2031 21.3 30.2031 22.1C30.2031 22.9 30.8031 23.5 31.6031 23.5C32.3031 23.5 33.0031 22.9 33.0031 22.1Z" fill="currentColor"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_5731_1365" x1="31.5482" y1="22.1299" x2="31.5482" y2="37.2417" gradientUnits="userSpaceOnUse">
                    <stop stopColor="currentColor"/>
                    <stop offset="0.9291" stopColor="currentColor" stopOpacity="0.0709255"/>
                    <stop offset="1" stopColor="currentColor" stopOpacity="0"/>
                </linearGradient>
                <clipPath id="clip0_5731_1365">
                    <rect width="52.8" height="47.6" fill="currentColor"/>
                </clipPath>
            </defs>
        </svg>

    )
}
import {useEffect, useState} from "react";
import MobileHalloweenDeco from "./MobileHalloweenDeco";
import MobileChristmasDeco from "./MobileChristmasDeco";

function DecoComponent() {
    switch (process.env.NEXT_PUBLIC_THEME) {
        case 'halloween':
            return <MobileHalloweenDeco/>
        case 'christmas':
            return <MobileChristmasDeco/>
        default:
            return null
    }
}

export default function MobileThemeDeco() {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setLoaded(true);
        }, 5000);
    }, []);

    return loaded && <DecoComponent/>
}

import {useRef, useState} from "react";
import {useRouter} from "next/router";
import useOutsideClick from "../hooks/useOutsideClick";
import Link from "next/link";
import Flag from "./Flag";
import SvgChevronDown from "../svg/SvgChevronDown";
import {setLocaleCookies} from "../helpers/cookies";

export default function LangSwitcher({className = ''}) {
    const {locale, locales, asPath} = useRouter();
    const switcherRef = useRef();
    const [active, setActive] = useState(false);

    useOutsideClick(switcherRef, () => setActive(false));

    const path = asPath.split('?')[0].split('#')[0];

    return (
        <div ref={switcherRef}
             onClick={() => setActive(!active)}
             className={`relative w-16 h-full ${!active ? 'cursor-pointer' : ''} text-0 ${className}`}
        >
            <div className={`absolute top-[1.35rem] left-0 inline-flex items-start gap-2 ${active ? 'active' : ''}`}>
                <div className="flex flex-col gap-3 border p-1.5 rounded-md bg-white">
                    <div className="rounded-3 border overflow-hidden">
                        <Flag locale={locale} width={28}/>
                    </div>

                    <div className={`${active ? 'flex' : 'hidden'} flex-col gap-3 dropdown`}>
                        {locales.map((lang, key) => {
                            if (lang === locale) return null;
                            return (
                                <Link href={path}
                                      locale={lang}
                                      key={`Nav Lang: ${key}`}
                                      className="block rounded-3 border overflow-hidden mouse-hover:border-gray-400 transition-colors"
                                      onClick={() => setLocaleCookies(lang)}
                                >
                                    <Flag locale={lang} width={28}/>
                                </Link>
                            );
                        })}
                    </div>
                </div>
                <div className="relative top-[0.9rem] text-gray-300 cursor-pointer">
                    <SvgChevronDown width={8}
                                    className={`transition-transform duration-300 ${active ? 'transform rotate-180' : ''}`}/>
                </div>
            </div>
        </div>
    )
}

import style from "../styles/components/MobileChristmasDeco.module.scss";
import {randomString} from "../helpers/strings";
import SvgSnowflake from "../svg/SvgSnowflake";
import {useMemo} from "react";

export default function MobileChristmasDeco() {
    const id = useMemo(() => randomString('mobile-christmas-deco'), []);

    const snowflakes = useMemo(() => {
        return Array.from({length: 16}, (_, i) => (
            <SvgSnowflake
                variant={i % 2}
                className={`absolute opacity-0 ${style.deco}`}
                key={`Decoration: ${id}-${i}`}
            />
        ));
    }, [id]);

    return (
        <div className="fixed top-[80px] left-0 w-full h-24 z-[25] pointer-events-none text-gray-300">
            {snowflakes}
        </div>
    )
}

import Link from "next/link";
import {useRouter} from "next/router";
import localized from "../locale/locales";
import SvgChevronDown from "../svg/SvgChevronDown";

export default function FooterCol({title, items, className = '', active, setActive}) {
    const {locale} = useRouter();
    const lang = localized[locale];

    return (
        <section className={`text-md md:text-sm border-t md:border-0 px-3 ${className}`}>
            <strong
                className="block font-bold flex items-center gap-2 justify-between py-5 md:py-0 cursor-pointer md:cursor-auto"
                onClick={setActive}
            >
                {title}
                <SvgChevronDown width={10}
                                className={`md:hidden transition-transform duration-200 will-change-transform ${active ? 'rotate-180' : ''}`}/>
            </strong>
            <div className={`accordion accordion-0 accordion-solid ${active ? 'accordion-open' : ''} md:no-accordion`}>
                <ul className="list-none flex flex-col gap-4 md:gap-1.5 pb-5 md:pt-3 md:pb-0">
                    {items.map((item, itemKey) => {
                        const {
                            label,
                            target = '',
                            noLocale = false
                        } = item;

                        const href = typeof item.href === 'object' ? item.href[locale] : item.href;
                        const hidden = item.hidden ? item.hidden(locale) : false;

                        if (hidden) return null;

                        return (
                            <li key={`FooterColItem: ${itemKey}`}>
                                <Link href={href}
                                      locale={noLocale ? false : locale}
                                      className={`mouse-hover:underline ${className}`}
                                      target={target}
                                      rel={target === '_blank' ? 'nofollow noreferrer' : ''}
                                >
                                    {label(lang)}
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </section>
    );
}

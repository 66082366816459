export default function SvgLogoBig({id = 'svg-logo-big', ...rest}) {
    return (
        <svg viewBox="0 0 207 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M40.6786 22.7296V19.4089H30.2012V12.9678H40.5927V9.61852H30.2012V3.69278H40.6786V0.37207H30.2012L26.4797 0.429324V3.69278V22.7296H40.6786Z"
                fill="currentColor"/>
            <path
                d="M206.743 22.7296V19.4089H196.265V12.9678H206.657V9.61852H196.265V3.69278H206.743V0.37207H196.265H192.572V3.69278V22.7296H206.743Z"
                fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M161.942 11.4221C161.942 17.949 157.075 22.9014 150.663 22.9014C144.25 22.9014 139.355 17.949 139.355 11.4221C139.355 4.9238 144.25 0 150.663 0C157.075 0 161.942 4.9238 161.942 11.4221ZM142.905 11.4507C142.905 16.0596 146.225 19.5807 150.634 19.5807C155.014 19.5807 158.363 16.0596 158.363 11.4507C158.363 6.87042 155.014 3.37796 150.634 3.37796C146.225 3.37796 142.905 6.87042 142.905 11.4507Z"
                  fill="currentColor"/>
            <path d="M87.14 22.7296V3.69278H94.4398V0.37207H76.1187V3.69278H83.4185V22.7296H87.14Z"
                  fill="currentColor"/>
            <path opacity="0.6" d="M17.3764 22.7295V2.43311H13.5977V22.7295H17.3764Z"
                  fill={`url(#paint0_linear_963:547e_${id})`}/>
            <path d="M3.77873 22.7296V5.06686L4.75204 7.09936L13.3114 22.7296H17.1474L4.9238 0.37207H0V22.7296H3.77873Z"
                  fill="currentColor"/>
            <path
                d="M17.3764 2.29006C17.3764 1.23087 16.5176 0.37207 15.487 0.37207C14.4278 0.37207 13.5977 1.23087 13.5977 2.29006C13.5977 3.34925 14.4565 4.20806 15.487 4.20806C16.5462 4.23668 17.3764 3.37788 17.3764 2.29006Z"
                fill="currentColor"/>
            <path opacity="0.6" d="M128.878 0.37207V21.1837H132.685V0.37207H128.878Z"
                  fill={`url(#paint1_linear_963:547e_${id})`}/>
            <path
                d="M128.878 20.9546C128.878 22.0138 129.736 22.8726 130.767 22.8726C131.798 22.8726 132.656 22.0138 132.656 20.9546C132.656 19.8954 131.798 19.0366 130.767 19.0366C129.736 19.0366 128.878 19.8954 128.878 20.9546Z"
                fill="currentColor"/>
            <path opacity="0.6" d="M58.9138 14.6857L66.9579 3.26364L63.7804 1.25977L49.3811 22.5581L58.9138 14.6857Z"
                  fill={`url(#paint2_linear_963:547e_${id})`}/>
            <path
                d="M67.2443 2.29006C67.2443 1.23087 66.3855 0.37207 65.3549 0.37207C64.2958 0.37207 63.4656 1.23087 63.4656 2.29006C63.4656 3.34925 64.3244 4.20806 65.3549 4.20806C66.3855 4.23668 67.2443 3.37788 67.2443 2.29006Z"
                fill="currentColor"/>
            <path opacity="0.6" d="M178.545 22.7298L186.332 3.29227L183.154 1.25977L174.595 22.7298H178.545Z"
                  fill={`url(#paint3_linear_963:547e_${id})`}/>
            <path
                d="M186.618 2.29006C186.618 1.23087 185.759 0.37207 184.728 0.37207C183.669 0.37207 182.839 1.23087 182.839 2.29006C182.839 3.34925 183.698 4.20806 184.728 4.20806C185.788 4.23668 186.618 3.37788 186.618 2.29006Z"
                fill="currentColor"/>
            <path
                d="M100.652 20.9546C100.652 19.8954 99.793 19.0366 98.7624 19.0366C97.7319 19.0366 96.873 19.8954 96.873 20.9546C96.873 22.0138 97.7319 22.8726 98.7624 22.8726C99.793 22.8726 100.652 22.0138 100.652 20.9546Z"
                fill="currentColor"/>
            <path
                d="M53.7037 22.7296L58.9138 14.8L64.1525 22.7296H68.5897L53.8183 0.37207H49.4384L56.7954 11.5365L49.3811 22.7296H53.7037Z"
                fill="currentColor"/>
            <path
                d="M112.303 22.7296V4.43707L113.476 8.12993L119.03 22.7296H122.981L131.569 0.37207H127.962L121.005 18.7505L114.164 0.37207H108.581V22.7296H112.303Z"
                fill="currentColor"/>
            <path d="M178.545 22.7296L169.556 0.37207H165.52L174.595 22.7296H178.545Z" fill="currentColor"/>
            <defs>
                <linearGradient id={`paint0_linear_963:547e_${id}`} x1="15.4869" y1="2.43892" x2="15.4869" y2="22.7319"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="currentColor"/>
                    <stop offset="0.9291" stopColor="currentColor" stopOpacity="0.0709255"/>
                    <stop offset="1" stopColor="currentColor" stopOpacity="0"/>
                </linearGradient>
                <linearGradient id={`paint1_linear_963:547e_${id}`} x1="130.783" y1="21.1775" x2="130.783" y2="0.38781"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="currentColor"/>
                    <stop offset="0.9291" stopColor="currentColor" stopOpacity="0.0709255"/>
                    <stop offset="1" stopColor="currentColor" stopOpacity="0"/>
                </linearGradient>
                <linearGradient id={`paint2_linear_963:547e_${id}`} x1="58.1746" y1="1.25843" x2="58.1746" y2="22.5733"
                                gradientUnits="userSpaceOnUse">
                    <stop offset="0.00281085" stopColor="currentColor"/>
                    <stop offset="1" stopColor="currentColor" stopOpacity="0"/>
                </linearGradient>
                <linearGradient id={`paint3_linear_963:547e_${id}`} x1="180.465" y1="1.25598" x2="180.465" y2="22.7429"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="currentColor"/>
                    <stop offset="0.9291" stopColor="currentColor" stopOpacity="0.0709255"/>
                    <stop offset="1" stopColor="currentColor" stopOpacity="0"/>
                </linearGradient>
            </defs>
        </svg>
    )
}
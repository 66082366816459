import {useEffect, useState} from "react";
import {useRouter} from "next/router";
import localized from "../locale/locales";
import Button from "./Button";
import SvgCross from "../svg/SvgCross";
import {getCookie, setCookie} from "../helpers/cookies";

export default function MobileFloatingCta({setActiveFloatingCta}) {
    const {locale, asPath} = useRouter();
    const lang = localized[locale];

    const [active, setActive] = useState(false);

    const path = asPath.split('?')[0].split('#')[0];

    const handleClickClose = () => {
        setActive(false);
        setCookie('nextFloatingCta', false, 24);
    }

    useEffect(() => {
        if (document) {
            setTimeout(() => {
                setActive(getCookie('nextFloatingCta') !== 'false');
            }, 1000);
        }
    }, []);

    useEffect(() => {
        setActiveFloatingCta(active);
    }, [setActiveFloatingCta, active]);

    return (
        <div
            className={`fixed bottom-0 left-0 w-full px-1.5 py-5 z-30 bg-white rounded-tl-xl rounded-tr-xl shadow-floating-cta lg:hidden transition-transform will-change-transform duration-500 ${!active ? 'translate-y-full' : ''}`}
        >
            <button
                className="absolute top-1.5 right-1.5 inline-flex w-7 h-7 bg-black text-white items-center justify-center rounded-md border-0 cursor-pointer"
                onClick={handleClickClose}
            >
                <SvgCross width={12} id="mobile-floating-cta"/>
            </button>
            <div className="text-center">
                <Button href={path === '/' ? '#free-trial' : '/#free-trial'}
                        label={lang.pages.freeTrial}
                        size="medium"
                />
            </div>
        </div>
    )
}

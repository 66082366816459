export default function SvgLogoChristmas(props) {
  return (
    <svg
      viewBox='0 0 38 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M18.5 31.4L7.30005 35.4L7.60005 23.5L0.300049 14.1L11.7 10.8L18.5 1L25.2001 10.8L36.6 14.1L29.3 23.5L29.6 35.4L18.5 31.4Z'
        fill='black'
      />
      <path
        opacity='0.6'
        d='M23.5 26.6V14.9H21.3V26.6H23.5Z'
        fill='url(#paint0_linear_161_2)'
      />
      <path
        d='M15.6 26.6V16.4L16.2 17.6L21.1 26.6H23.3L16.3 13.7H13.4V26.6H15.6Z'
        fill='currentColor'
      />
      <path
        d='M23.5 14.9C23.5 14.3 23 13.8 22.4 13.8C21.8 13.8 21.3 14.3 21.3 14.9C21.3 15.5 21.8 16 22.4 16C23 16 23.5 15.5 23.5 14.9Z'
        fill='currentColor'
      />
      <defs>
        <linearGradient
          id='paint0_linear_161_2'
          x1='22.3863'
          y1='14.9115'
          x2='22.3863'
          y2='26.6194'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='currentColor' />
          <stop
            offset='0.9291'
            stopColor='currentColor'
            stopOpacity='0.0709255'
          />
          <stop
            offset='1'
            stopColor='currentColor'
            stopOpacity='0'
          />
        </linearGradient>
      </defs>
    </svg>
  );
}

export const randomString = (prefix = '') => {
    return prefix + (Math.random() + 1).toString(36).substring(7);
};

export const validateEmail = (email) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const ucFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const stripHtml = (html) => {
    return html.replace(/<\/?[^>]+(>|$)/g, "");
}

export const nl2br = (str) => {
    return str.split('\n').join('<br>');
}
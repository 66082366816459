import karlinIntroImage from "../public/img/locations/karlin/intro.jpg";
import karlinHeaderImage from "../public/img/locations/karlin/header_video_thumb.jpg";
import karlinMapImage from "../public/img/locations/karlin/map.png";
import karlinMapWideImage from "../public/img/locations/karlin/map_wide.png";

import holesoviceIntroImage from "../public/img/locations/holesovice/intro.jpg";
import holesoviceHeaderImage from "../public/img/locations/holesovice/header_video_thumb.jpg";
import holesoviceMapImage from "../public/img/locations/holesovice/map.png";
import holesoviceMapWideImage from "../public/img/locations/holesovice/map.png";

import vinohradyHeaderImage from "../public/img/locations/vinohrady/header.jpg";
import vinohradyIntroImage from "../public/img/locations/vinohrady/intro.jpg";
import vinohradyMapImage from "../public/img/locations/vinohrady/map.png";
import vinohradyMapWideImage from "../public/img/locations/vinohrady/map_wide.png";

import smichovHeaderImage from "../public/img/locations/smichov/header.jpg";
import smichovIntroImage from "../public/img/locations/smichov/intro.jpg";
import smichovMapImage from "../public/img/locations/smichov/map.png";
import smichovMapWideImage from "../public/img/locations/smichov/map_wide.png";

const locationsOrder = ["smichov", "karlin", "holesovice", "vinohrady"];
const sortLocations = (a, b) => {
  return locationsOrder.indexOf(a) - locationsOrder.indexOf(b);
};

const baseLinks = {
  holesovice: "https://www.mysports.com/studio/bmV4dG1vdmU6MTIxMDAwMjI5MA%3D%3D",
  vinohrady: "https://www.mysports.com/studio/bmV4dG1vdmU6MTIxMTczODkwMA%3D%3D",
  karlin: "https://www.mysports.com/studio/bmV4dG1vdmU6MTIxMjk5NTMyMA%3D%3D",
  smichov: "https://www.mysports.com/studio/bmV4dG1vdmU6MTIxNjE1NDY3MA%3D%3D",
};

export const locationData = {
  none: {
    pins: {
      square: [],
      wide: [],
    },
    images: {
      map: {
        square: "",
        wide: "",
      },
    },
  },
  holesovice: {
    status: "opened",
    studioId: 1210002290,
    showInBooking: true,
    showInContacts: true,
    headerVideo: "/videos/locations/holesovice/header.mp4",
    // introVideo: '/videos/locations/holesovice/intro.mp4',
    introVideo: null,
    pins: {
      square: [
        {
          top: "43%",
          left: "54%",
          type: "logo",
        },
      ],
      wide: [
        {
          top: "36%",
          left: "54%",
          type: "logo",
        },
      ],
    },
    images: {
      header: holesoviceHeaderImage,
      intro: holesoviceIntroImage,
      map: {
        square: holesoviceMapImage,
        wide: holesoviceMapWideImage,
      },
    },
    contacts: {
      email: "holesovice@nextmove.cz",
      phone: "+420 603 226 363",
    },
    address: {
      street: "Dělnická 9",
      district: "Holešovice",
      city: "Holešovice - Praha 7",
    },
    accountLink: `${baseLinks.holesovice}/selfservice`,
    bookClassLink: `${baseLinks.holesovice}/course`,
  },
  karlin: {
    status: "opened",
    studioId: 1212995320,
    showInBooking: true,
    showInContacts: true,
    headerVideo: "/videos/locations/karlin/header.mp4",
    introVideo: null, //'/videos/locations/karlin/intro.mp4',
    pins: {
      square: [
        {
          top: "31%",
          left: "60%",
          type: "logo",
        },
      ],
      wide: [
        {
          top: "36%",
          left: "54%",
          type: "logo",
        },
      ],
    },
    images: {
      header: karlinHeaderImage,
      intro: karlinIntroImage,
      map: {
        square: karlinMapImage,
        wide: karlinMapWideImage,
      },
    },
    contacts: {
      email: "karlin@nextmove.cz",
      phone: "+420 739 017 600",
    },
    address: {
      street: "Rohanské nábřeží 713/8",
      district: "Karlín",
      city: "Karlín - Praha 8",
    },
    accountLink: `${baseLinks.karlin}/selfservice`,
    bookClassLink: `${baseLinks.karlin}/course`,
  },
  vinohrady: {
    status: "opened",
    studioId: 1211738900,
    showInBooking: true,
    showInContacts: true,
    headerVideo: "/videos/locations/vinohrady/header.mp4",
    introVideo: null, //'/videos/locations/vinohrady/intro.mp4',
    pins: {
      square: [
        {
          top: "60%",
          left: "47.5%",
          type: "logo",
        },
      ],
      wide: [
        {
          top: "48%",
          left: "47.5%",
          type: "logo",
        },
      ],
    },
    images: {
      header: vinohradyHeaderImage,
      intro: vinohradyIntroImage,
      map: {
        square: vinohradyMapImage,
        wide: vinohradyMapWideImage,
      },
    },
    contacts: {
      email: "vinohrady@nextmove.cz",
      phone: "+420 604 994 222",
    },
    address: {
      street: "Korunní 108",
      district: "Vinohrady",
      city: "Vinohrady - Praha 10",
    },
    accountLink: `${baseLinks.vinohrady}/selfservice`,
    bookClassLink: `${baseLinks.vinohrady}/course`,
  },
  smichov: {
    status: "opened",
    studioId: 1216154670,
    showInBooking: true,
    showInContacts: true,
    headerVideo: "/videos/locations/smichov/header.mp4",
    introVideo: null, //'/videos/locations/smichov/intro.mp4',
    pins: {
      square: [
        {
          top: "53.3%",
          left: "49.5%",
          type: "logo",
        },
      ],
      wide: [
        {
          top: "48.1%",
          left: "36.85%",
          type: "logo",
        },
      ],
    },
    images: {
      header: smichovHeaderImage,
      intro: smichovIntroImage,
      map: {
        square: smichovMapImage,
        wide: smichovMapWideImage,
      },
    },
    contacts: {
      email: "smichov@nextmove.cz",
      phone: "+420 734 883 774",
    },
    address: {
      street: "Svornosti 504/9",
      district: "Smíchov",
      city: "Smíchov - Praha 5",
    },
    accountLink: `${baseLinks.smichov}/selfservice`,
    bookClassLink: `${baseLinks.smichov}/course`,
  },
};

export const getLocationData = (location) => {
  return locationData[location] ?? locationData.none;
};

export const getBookableLocations = () => {
  return Object.keys(locationData)
    .filter((location) => locationData[location].showInBooking)
    .sort(sortLocations);
};

export const getContactableLocations = () => {
  return Object.keys(locationData)
    .filter((location) => locationData[location].showInContacts)
    .sort(sortLocations);
};

export const locationsEnum = {
  KARLIN: "Karlín",
  HOLESOVICE: "Holešovice",
  VINOHRADY: "Vinohrady",
  SMICHOV: "Smíchov",
};

export const getLocationContacts = (location) => {
  return locationData[location]?.contacts ?? null;
};

export const getLocationAddress = (location) => {
  return locationData[location]?.address ?? null;
};

export const getLocationBookClassLink = (location) => {
  return locationData[location]?.bookClassLink ?? null;
};

export const getLocationAccountLink = (location) => {
  return locationData[location]?.accountLink ?? null;
};

export const getLocationStudioId = (location) => {
  return locationData[location]?.studioId ?? null;
};

export default function SvgCross({id = 'svg-cross', ...rest}) {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M11.9696 10.2114L0.0131836 22.1678L1.84522 23.9999L13.8016 12.0435L11.9696 10.2114Z"
                fill="currentColor"/>
            <path
                d="M1.8186 0.00244081L-0.0134277 1.83447L22.1445 23.9924L23.9765 22.1604L1.8186 0.00244081Z"
                fill="currentColor"/>
            <path opacity="0.6"
                  d="M12.191 13.6363L10.3638 11.8091L21.682 0.463623L23.5365 2.2909L12.191 13.6363Z"
                  fill={`url(#svg_x_${id})`}/>
            <path
                d="M21.2456 0.927272C20.9456 1.63636 21.2456 2.45455 21.9546 2.75455C22.6637 3.05455 23.4819 2.75455 23.7819 2.04545C24.0819 1.33636 23.7819 0.518181 23.0728 0.218181C22.3637 -0.109091 21.5456 0.218181 21.2456 0.927272Z"
                fill="currentColor"/>
            <defs>
                <linearGradient id={`svg_x_${id}`} x1="10.2222" y1="6.87933" x2="23.6374" y2="6.52484"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="currentColor" stopOpacity="0"/>
                    <stop offset="1" stopColor="currentColor"/>
                </linearGradient>
            </defs>
        </svg>
    )
}
export default function SvgFlagEN(props) {
    return (
        <svg {...props} viewBox="0 0 28 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28 0.00464413H0V16.9954H28V0.00464413Z" fill="#012169"/>
                <path d="M14.0333 7.74261L0.00195312 16.9951L0.00240112 16.9958L0.00402512 16.9984L0.00508912 17H3.58309L14.0333 10.1089V7.74261Z" fill="white"/>
                <path d="M14.0333 7.74091L0.000728 16.9942L0 16.9947L0.000728 16.9958L0.001176 16.9965L0.002744 16.999L0.003416 17H2.55522L10.9571 11.4597L10.9572 11.4596L14.0333 9.43115V7.74091Z" fill="#C8102E"/>
                <path d="M14.0333 5.37716L0.000728 14.6304V16.9942L0 16.9947L0.000728 16.9958V16.9968L0.001176 16.9966L0.002352 16.9958L14.0333 7.74358V5.37716Z" fill="white"/>
                <path d="M14.0335 9.25899L0.00646455 0.00928826L0.00260056 0.00668301L0.00215256 0.00736264L0.00506455 0.00928826H0.000976562V2.37202L14.0335 11.6253V9.25899Z" fill="white"/>
                <path d="M14.0335 9.25723L0.00915254 0.00928826L0.00338456 0.00549366L0.00209656 0.00464413L0.00131256 0.00589012L0.00260056 0.00668302L0.00215256 0.00736265L0.00506455 0.00928826H0.000976562V1.6942L14.0335 10.9475V9.25723Z" fill="#C8102E"/>
                <path d="M14.0333 6.89351L3.59339 0.00928822H0.00895311L0.00318512 0.00549364L0.00240112 0.00668299L0.00195312 0.00736262L0.00486512 0.00928822L14.0333 9.25985V6.89351Z" fill="white"/>
                <path d="M13.9668 9.25743L27.9982 0.00487069L27.9977 0.00419106L27.9961 0.00164244L27.995 0H24.417L13.9668 6.89107V9.25743Z" fill="white"/>
                <path d="M13.9668 9.25909L27.9993 0.00577684L28.0001 0.00532376L27.9993 0.00419104L27.9989 0.00345478L27.9973 0.00101944L27.9966 0H25.4448L17.043 5.54033L17.0428 5.54044L13.9668 7.56885V9.25909Z" fill="#C8102E"/>
                <path d="M13.9668 11.6229L27.9993 2.36959V0.00577686L28.0001 0.00532377L27.9993 0.00419105V0.0031716L27.9989 0.00345478L27.9977 0.00419105L13.9668 9.25647V11.6229Z" fill="white"/>
                <path d="M13.9668 7.74104L27.9938 16.9907L27.9977 16.9933L27.9982 16.9926L27.9952 16.9907H27.9993V14.628L13.9668 5.37469V7.74104Z" fill="white"/>
                <path d="M13.9668 7.74278L27.9912 16.9907L27.9969 16.9945L27.9982 16.9954L27.999 16.9941L27.9977 16.9933L27.9982 16.9926L27.9952 16.9907H27.9993V15.3058L13.9668 6.05249V7.74278Z" fill="#C8102E"/>
                <path d="M13.9668 10.1065L24.4067 16.9907H27.9912L27.9969 16.9945L27.9977 16.9933L27.9982 16.9926L27.9952 16.9907L13.9668 7.74006V10.1065Z" fill="white"/>
                <path d="M28 5.66822H0V11.3318H28V5.66822Z" fill="white"/>
                <path d="M16.8002 0.00464413H11.2002V16.9954H16.8002V0.00464413Z" fill="white"/>
                <path d="M28 6.51775H0V10.4822H28V6.51775Z" fill="#C8102E"/>
                <path d="M15.96 0.00464413H12.04V16.9954H15.96V0.00464413Z" fill="#C8102E"/>
        </svg>
    )
}
import {useState} from "react";
import Link from "next/link";
import SvgCross from "../svg/SvgCross";
import {setCookiePreferences} from "../helpers/cookies";
import {useRouter} from "next/router";
import localized from "../locale/locales";

export default function CookieBanner() {
    const {locale} = useRouter();
    const lang = localized[locale];

    const [isVisible, setIsVisible] = useState(true);
    const [isActive, setIsActive] = useState(true);

    const handleClose = () => {
        setIsActive(false);

        setTimeout(() => {
            setIsVisible(false);
        }, 500);
    };

    const handleAgree = () => {
        setCookiePreferences('all');

        setIsActive(false);

        setTimeout(() => {
            setIsVisible(false);
        }, 500);
    };

    return isVisible && (
        <aside
            className={`fixed bottom-0 md:bottom-8 left-1/2 -translate-x-1/2 z-30 w-full max-w-3xl px-2 transition-all duration-500 ${!isActive ? 'translate-y-1/2 opacity-0' : ''}`}>
            <div
                className="p-4 md:p-6 rounded-tl-lg rounded-tr-lg md:rounded-br-lg md:rounded-bl-lg bg-black text-white flex flex-col-reverse md:flex-row items-end md:items-start gap-4">
                <div className="flex flex-col gap-4">
                    <p className="text-center leading-relaxed text-xs font-medium">
                        {lang.cookies.banner.body}
                    </p>
                    <div className="flex flex-col md:flex-row items-center justify-center gap-4 md:gap-8">
                        <button
                            className="py-2 px-8 uppercase bg-white text-black mouse-hover:bg-gray-200 transition-colors duration-300 rounded-full text-xs font-medium"
                            onClick={handleAgree}
                        >
                            {lang.common.agree}
                        </button>
                        <Link href="/cookie-settings"
                              className="py-2 uppercase underline mouse-hover:no-underline rounded-full text-xs font-medium"
                        >
                            {lang.cookies.banner.changeSettings}
                        </Link>
                    </div>
                </div>
                <button onClick={handleClose} aria-label={lang.common.close}>
                    <SvgCross width={18} id="cookie-banner"/>
                </button>
            </div>
        </aside>
    )
}

import {useMemo, useState} from "react";
import {buttonStyles} from "../helpers/styles";
import ButtonOrLink from "./ButtonOrLink";
import SvgBat from "../svg/SvgBat";
import styles from '../styles/components/ButtonHalloween.module.scss';
import {randomString} from "../helpers/strings";

function Decorations({theme = 'primary'}) {
    const id = useMemo(() => randomString('button-halloween-decorations'), []);
    const deco = useMemo(() => {
        return Array.from({length: 25}, (_, i) => {
                const variant = i % 3;
                const themeClasses = theme === 'primary' ? 'fill-black' : 'fill-white';
                const aspectClasses = variant === 3 ? 'aspect-[25/8]' : (variant === 2 ? 'aspect-[15/7]' : 'aspect-[11/5]');

                return (
                    <SvgBat variant={variant}
                            className={`absolute opacity-0 ${styles.deco} ${themeClasses} ${aspectClasses}`}
                            key={`Decoration: ${id}-${i}`}
                    />
                )
            }
        );
    }, [id, theme]);

    return (
        <div className="overlay pointer-events-none">
            {deco}
        </div>
    )
}

export default function ButtonHalloween({label, href, className = '', theme = 'primary', size = 'big', ...rest}) {
    const [isHovered, setIsHovered] = useState(false);
    const buttonClasses = buttonStyles(theme, size, false);

    return (
        <div className={`relative ${styles.button} inline-flex ${className}`}>
            <ButtonOrLink className={`${buttonClasses} relative z-10 w-full`}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseOut={() => setIsHovered(false)}
                          href={href}
                          {...rest}
            >
                {label}
            </ButtonOrLink>
            {isHovered && <Decorations theme={theme}/>}
        </div>
    )
}

import VoucherBg2 from "../components/voucher-bg/VoucherBg2";
import VoucherBg3 from "../components/voucher-bg/VoucherBg3";
import { getLocationAccountLink, getLocationBookClassLink } from "./locations";

export const apiBase = "https://nextmove.api.magicline.com";

export const socialLinks = {
  facebook: "https://www.facebook.com/nextmove.cz",
  instagram: "https://www.instagram.com/nextmove.cz",
};

export const navLinks = [
  {
    type: "link",
    href: "/#memberships",
    label: (lang) => lang.pages.memberships,
  },
  {
    type: "link",
    href: "/concepts",
    label: (lang) => lang.pages.concepts,
  },
  {
    type: "link",
    href: "/trainers",
    label: (lang) => lang.pages.trainers,
  },
  {
    type: "submenu",
    label: (lang) => lang.pages.locations,
    items: [
      {
        href: "/locations/smichov",
        label: (lang) => lang.pages.locationSmichov,
      },
      {
        href: "/locations/karlin",
        label: (lang) => lang.pages.locationKarlin,
      },
      {
        href: "/locations/holesovice",
        label: (lang) => lang.pages.locationHolesovice,
      },
      {
        href: "/locations/vinohrady",
        label: (lang) => lang.pages.locationVinohrady,
      },
    ],
  },
  {
    type: "submenu",
    label: (lang) => lang.pages.nextMove,
    items: [
      {
        href: "/about-us",
        label: (lang) => lang.pages.aboutUs,
      },
      {
        href: "/b2b",
        label: (lang) => lang.pages.b2b,
      },
      {
        href: '/vouchers',
        label: (lang) => lang.pages.vouchers,
      },
      {
        href: "/jobs",
        label: (lang) => lang.pages.jobs,
      },
      {
        href: "/contacts",
        label: (lang) => lang.pages.contacts,
      },
    ],
  },
  // {
  //     type: 'submenu',
  //     label: (lang) => lang.pages.concepts,
  //     items: [
  //         {
  //             href: '/biostrength',
  //             label: (lang) => lang.pages.bioCircuit,
  //         },
  //         {
  //             href: '/next-burn',
  //             label: (lang) => lang.pages.nextBurn,
  //         },
  //         {
  //             href: '/yoga',
  //             label: (lang) => lang.pages.yoga,
  //         },
  //         {
  //             href: '/gym',
  //             label: (lang) => lang.pages.gym,
  //         }
  //     ]
  // },
  {
    type: "button",
    href: "/#free-trial",
    label: (lang) => lang.pages.freeTrial,
  },
];

export const footerCols = [
  [
    {
      // todo: holesovice?
      href: getLocationAccountLink("holesovice"),
      target: "_blank",
      label: (lang) => lang.pages.myAccount,
    },
    {
      // todo: make this a popup
      href: getLocationBookClassLink("holesovice"),
      target: "_blank",
      label: (lang) => lang.pages.bookNextClass,
    },
    {
      href: "/#memberships",
      label: (lang) => lang.pages.memberships,
    },
    {
      href: '/vouchers',
      label: (lang) => lang.pages.vouchers
    },
    {
      href: "/#free-trial",
      label: (lang) => lang.pages.freeTrial,
    },
  ],
  [
    // {
    //     href: '/gym',
    //     label: (lang) => lang.pages.gym
    // },
    // {
    //     href: '/biostrength',
    //     label: (lang) => lang.pages.bioCircuit
    // },
    // {
    //     href: '/next-burn',
    //     label: (lang) => lang.pages.nextBurn
    // },
    // {
    //     href: '/yoga',
    //     label: (lang) => lang.pages.yoga
    // },
    {
      href: "/concepts",
      label: (lang) => lang.pages.concepts,
    },
    {
      href: "/trainers",
      label: (lang) => lang.pages.trainers,
    },
    {
      href: "/about-us",
      label: (lang) => lang.pages.aboutUs,
    },
    {
      href: "/b2b",
      label: (lang) => lang.pages.b2b,
    },
    {
      href: "/jobs",
      label: (lang) => lang.pages.jobs,
    },
  ],
  [
    {
      href: "/faq",
      label: (lang) => lang.pages.faq,
    },
    {
      href: "/media",
      label: (lang) => lang.pages.media,
      hidden: (locale) => locale !== "cs",
    },
    {
      href: "/zpsc",
      target: "_blank",
      label: (lang) => lang.docs.cookies,
      noLocale: true,
    },
    {
      href: "/zzou",
      target: "_blank",
      label: (lang) => lang.docs.personalData,
      noLocale: true,
    },
    {
      href: "/cookie-settings",
      label: (lang) => lang.pages.cookies,
    },
    {
      href: {
        cs: "/vop",
        en: "/vop-en",
      },
      target: "_blank",
      label: (lang) => lang.docs.terms,
      noLocale: true,
    },
    {
      href: "/provozni-rad",
      target: "_blank",
      label: (lang) => lang.docs.rules,
      noLocale: true,
    },
  ],
];

export const vouchers = [
  {
    premium: false,
    theme: "dark",
    slug: "next-level-1",
    price: 2490,
    link: "https://www.mysports.com/studio/bmV4dG1vdmU6MTIxMDAwMjI5MA%3D%3D/booking/voucher/1225573680",
    background: <VoucherBg3 />,
  },
  {
    premium: false,
    theme: "dark",
    slug: "next-start",
    price: 4550,
    link: "https://www.mysports.com/studio/bmV4dG1vdmU6MTIxMDAwMjI5MA%3D%3D/booking/voucher/1225573961",
    background: <VoucherBg2 />,
  },
  {
    premium: false,
    theme: "dark",
    price: 11940,
    priceBlackFriday: 9999,
    slug: "next-level-6",
    link: "https://www.mysports.com/studio/bmV4dG1vdmU6MTIxMDAwMjI5MA%3D%3D/booking/voucher/1225573960",
    background: <VoucherBg3 />,
  },
  // {
  //     premium: true,
  //     theme: 'light',
  //     slug: 'next-you',
  //     title: (lang) => lang.vouchers.items[3].title,
  //     subtitle: (lang) => lang.vouchers.items[3].subtitle,
  //     small: (lang) => lang.vouchers.items[3].small,
  //     price: (lang) => lang.vouchers.items[3].price,
  //     list: (lang) => lang.vouchers.items[3].list,
  //     link: 'https://buy.stripe.com/8wM7wmfrMcsE9YQ144',
  //     background: <VoucherBg3/>
  // },
];

export const cookieGroups = [
  {
    disabled: true,
    name: "required",
    title: (lang) => lang.cookies.items.required.title,
    list: (lang) => lang.cookies.items.required.list,
  },
  {
    disabled: false,
    name: "analytics_storage",
    title: (lang) => lang.cookies.items.analyticsStorage.title,
    list: (lang) => lang.cookies.items.analyticsStorage.list,
  },
  {
    disabled: false,
    name: "ad_storage",
    title: (lang) => lang.cookies.items.adStorage.title,
    list: (lang) => lang.cookies.items.adStorage.list,
  },
];

import Link from "next/link";
import {useRouter} from "next/router";
import localized from "../locale/locales";
import SvgLogoHalloween from "../svg/SvgLogoHalloween";
import SvgLogoSmall from "../svg/SvgLogoSmall";
import SvgLogoChristmas from "../svg/SvgLogoChristmas";

function LogoComponent() {
    switch (process.env.NEXT_PUBLIC_THEME) {
        case "halloween":
            return (
                <div className="text-white">
                    <SvgLogoHalloween id="nav-logo" width={53}/>
                </div>
            );
        case "christmas":
            return (
                <div className="text-white">
                    <SvgLogoChristmas id="nav-logo" width={50}/>
                </div>
            );
        default:
            return <SvgLogoSmall id="nav-logo" width={21}/>;
    }
}

export default function LogoLink() {
    const {locale} = useRouter();
    const lang = localized[locale];

    return (
        <Link href="/" className="block text-0">
            <LogoComponent/> {lang.nav.back}
        </Link>
    );
}

import Link from "next/link";
import useScrollTo from "react-spring-scroll-to-hook";
import {config} from "@react-spring/web";

export default function ButtonOrLink({href, children, ...rest}) {
    const {scrollTo} = useScrollTo(config.default);

    if (href) {
        // If link to anchor
        if (href[0] === '#') {
            const handleClick = (e) => {
                scrollTo(href, -100);
                e.target.blur();
            }

            return (
                <button onClick={handleClick} {...rest}>
                    {children}
                </button>
            )
        }

        return (
            <Link href={href} {...rest}>
                {children}
            </Link>
        )
    }

    return (
        <button {...rest}>
            {children}
        </button>
    )
}

import {useRouter} from "next/router";
import localized from "../locale/locales";
import SvgChevronDown from "../svg/SvgChevronDown";
import NavSubmenuLink from "./NavSubmenuLink";

export default function NavSubmenu({label, className = '', items}) {
    const {locale} = useRouter();
    const lang = localized[locale];

    const classes = `inline-flex items-center gap-1.5 py-7 px-5 text-sm transition-all duration-300 group-hover:bg-black group-hover:text-white cursor-default ${className}`;

    return (
        <div className="relative group will-change-transform">
            <div className={classes}>
                {label}<SvgChevronDown width={6}/>
            </div>
            <div
                className="absolute top-full left-1/2 -translate-x-1/2 w-[120%] min-w-max pt-4 opacity-0 group-hover:opacity-100 translate-y-4 group-hover:translate-y-0 pointer-events-none group-hover:pointer-events-auto will-change-transform transition-all duration-300"
            >
                <div
                    className="absolute top-1 left-1/2 -translate-x-1/2 translate-y-1/2 w-3.5 h-3.5 bg-white rounded-[3px] rotate-45 pointer-events-none"/>
                <div className="bg-white rounded-lg text-center px-6 shadow-xl">
                    {items && items.map(({href, label}, key) => {
                        return (
                            <NavSubmenuLink key={`NavSubmenuItem: ${key}`}
                                            href={href}
                                            label={label(lang)}
                                            className="border-b last-of-type:border-0"
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default function Container({children, className = '', fullMobile = false, width = 'normal'}) {
    let widthClasses;
    let paddingClasses = '';

    if (width === 'wide') {
        widthClasses = 'max-w-9xl';

        if (!fullMobile) {
            paddingClasses = 'px-3';
        }

        paddingClasses += ' md:px-3';

    } else if (width === 'normal') {
        widthClasses = 'max-w-7xl';

        if (!fullMobile) {
            paddingClasses = 'px-6';
        }

        paddingClasses += ' md:px-10';
    }


    return (
        <div
            className={`w-full ${widthClasses} ${paddingClasses} ${!fullMobile ? 'px-6' : ''} md:px-10 mx-auto ${className}`}
        >
            {children}
        </div>
    )
}
